// vibrant colors
$mm-turquoise: #2fb6bc;
$mm-pink: #ff278d;
$mm-lime-green: #afc236;
$mm-blue: #2b86c7;
$mm-yellow: #ffd400;
$mm-orange: #e95a1a;

$dark-grayish-cerulean: #2e3f44;

// dark vibrant colors
$mm-dark-turquoise: #009695;
$mm-purple: #8e3f91;
$mm-green: #4cba7f;
$mm-dark-blue: #216b99;
$mm-gold: #f8ac04;
$mm-red: #e42925;

$mm-light-blue: #8cc2e9;
$mm-light-red: #e08083;
$mm-light-purple: #ab88be;

// neutral colors
$mm-white: #ffffff;
$mm-black: #000000;
$mm-stone: #edebe6;
$mm-light-stone: #f6f5f2;
$mm-dark-stone: #c9c2bf;
$mm-grey-text: #575756;

$mm-ocean-blue: #0c3483;

$payne-grey: #536e77;

$defaultColors: #53b585, #a9b687, #7dbce6, #df7f82, #9f83ba, #7b89ba, #c69979,
  #8fc1c3, $mm-turquoise, $mm-lime-green, $mm-blue, $mm-purple, $mm-dark-blue,
  $mm-yellow, $mm-dark-turquoise, $mm-green, $mm-gold, $mm-light-blue,
  $mm-light-red, $mm-light-purple;
$chartColors: $defaultColors;
$chartColorsCount: length($chartColors);
