@import "../../styles/colors";

.page-footer {
  text-align: center;
  padding-top: 2em;

  .ce-logo {
    height: 80px;
    width: auto;
    margin: 1em 0 0.75em;
  }

  .copyright-text {
    color: $mm-grey-text;
    font-size: 0.8em;
  }
}
